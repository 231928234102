import Pagination from "@/component/pagination/index.vue";
import CascadeRadioBox from "@/page/admin/company-management/staff-management/component/cascade-radio-box";
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";
import consumer_budget_getBudgetExecuteCollectList from '@/lib/data-service/haolv-default/consumer_budget_getBudgetExecuteCollectList';
import consumer_budget_getBudgetExecuteDetailList from '@/lib/data-service/haolv-default/consumer_budget_getBudgetExecuteDetailList';
import BudgetRadioBox from "@/component/budget-radio-box/index.vue";
import consumer_budget_getBudgetOrgList from "@/lib/data-service/haolv-default/consumer_budget_getBudgetOrgList";
import consumer_budget_getBudgetItem from '@/lib/data-service/haolv-default/consumer_budget_getBudgetItem';
import consumer_budget_getBudgetInsurance from '@/lib/data-service/haolv-default/consumer_budget_getBudgetInsurance';
import consumer_budget_getBudgetPro from '@/lib/data-service/haolv-default/consumer_budget_getBudgetPro';
import consumer_budget_getBudgetBizType from '@/lib/data-service/haolv-default/consumer_budget_getBudgetBizType';
export default {
    data() {
        return {
            params: {
                "budgetEndMonth": "",
                "budgetInsuranceCode": "",
                "budgetInsuranceName": "",
                budgetItemCodeAndName: '',
                "budgetItemCode": "",
                "budgetItemName": "",
                "budgetOrgCode": "",
                "budgetOrgName": "",
                "budgetProCode": "",
                "budgetProName": "",
                "budgetStartMonth": "",
                "budgetYear": "",
                "currentPage": 1,
                "keyword": "",
                "orderBy": "",
                "pageSize": 10,

                budgetMonth: [],
                budgetDateType: '1',
            },
            total: 0,
            dateOptions: [{value: '1', label: '按月'}, {value: '2', label: '按年'}],
            budgetItemCodeOptions: [],
            budgetInsuranceCodeOptions: [],
            budgetProCodeOptions: [],

            tableData: [],

            cascadeList: [],
            treeDataSource: [], // 全部部门list
            activeList: [], // 筛选出来部门list
            companyInfo: {},

            infoParams: {
                "budgetInsuranceCode": "",
                "budgetInsuranceName": "",
                budgetInsuranceCodeAndName: '',
                "budgetItemCode": "",
                "budgetItemName": "",
                budgetItemCodeAndName: '',
                "budgetOrgCode": "",
                "budgetOrgName": "",
                "budgetProCode": "",
                "budgetProName": "",
                budgetProCodeAndName: '',
                "budgetUseType": 0,
                "budgetUsedEndDate": "",
                "budgetUsedStartDate": "",
                "createEndDate": "",
                "createStartDate": "",
                "currentPage": 1,
                "keyword": "",
                "orderBy": "",
                "pageSize": 10,
                "userName": "",
                "bizType": "",
                budgetUsedDate: [],
                createDate: [],
                total: 0,
            },
            budgetList: [], // 预算科目枚举列表
            budgetInsuranceList: [], // 预算险类枚举列表
            budgetProLis: [], // 项目枚举列表
            bizTypeLis: [], //类型枚举列表
            showMoreSelect: false,
            detailList: [],
            showUsedSettlingAmountDialog: false,
            detailLoading: false,
        }
    },
    props: {},
    components: {BudgetRadioBox, Pagination},
    created() {
    },
    mounted() {
    },
    async activated() {
        await this.getBudgetOrgList();
        this.treeDataSource = this.initTreeData(this.cascadeList)

        await this.getStaffList();
        this.getBudgetItem();
        this.getBudgetInsurance();
        this.getBudgetPro();
        this.getBudgetBizType();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        toSelect(val) {
            if (this.activeList.length === 0) {
                this.params.budgetOrgCode = ''
                this.params.budgetOrgName = ''
            } else {
                /*let deptIds = []
                this.activeList.forEach(value => {
                    deptIds.push(value.deptId)
                })*/
                this.params.budgetOrgCode = val.budgetOrgId
                this.params.budgetOrgName = val.budgetOrgName
            }
            this.search()
        },
        delSelect() {
            this.params.budgetOrgCode = '';
            this.params.budgetOrgName = '';
            this.search();
        },
        async getBudgetOrgList() {
            let [err, res] = await awaitWrap(consumer_budget_getBudgetOrgList())
            if (err) {
                this.cascadeList = []
                return
            }
            this.companyInfo = {
                companyName: res.datas.companyName,
                companyId: res.datas.companyId,
            };
            this.cascadeList = res.datas.departmentListResults || []
        },
        initTreeData(list) {
            // 这里一定要转化，要不然他们的值监听不到变化
            let tempData = JSON.parse(JSON.stringify(list))
            let reduceDataFunc = (data, level) => {
                data.map((m, i) => {
                    m.isExpand = false // 是否展开
                    m.check = false
                    m.indeterminate = false
                    // m.children = m.children || []
                    m.level = level
                    m.bLeft = level * 15
                    m.label = m.budgetOrgName
                    m.value = m.budgetOrgId
                    m.children = m.childrenList
                    delete m.childrenList
                    if (m.children && m.children.length > 0) {
                        reduceDataFunc(m.children, level + 1)
                    }
                })
            }
            reduceDataFunc(tempData, 1)
            return tempData
        },
        changeDateType(val) {
            console.log(val)
            if (val === '1') {
                this.params.budgetYear = '';
            } else {
                this.params.budgetMonth = [];
                this.params.budgetStartMonth = '';
                this.params.budgetEndMonth = '';
            }
        },
        changeMonthDate(val) {
            console.log(val)
            if (val) {
                this.params.budgetStartMonth = val[0];
                this.params.budgetEndMonth = val[1];
            } else {
                this.params.budgetStartMonth = '';
                this.params.budgetEndMonth = '';
            }
        },
        changeBudgetCode(val) {
            console.log(val)
            if (val) {
                this.budgetList.forEach(value => {
                    if (value.value2 === val) {
                        this.params.budgetItemCode = value.code;
                        this.params.budgetItemName = value.label;
                    }
                })
            } else {
                this.params.budgetItemName = '';
            }
        },
        changeBudgetInsuranceCode(val) {
            if (val) {
                this.budgetInsuranceCodeOptions.forEach(value => {
                    if (value.value === val) {
                        this.params.budgetInsuranceName = value.label;
                    }
                })
            } else {
                this.params.budgetInsuranceName = '';
            }
        },
        changeBudgetProCode(val) {
            if (val) {
                this.budgetProCodeOptions.forEach(value => {
                    if (value.value === val) {
                        this.params.budgetProName = value.label;
                    }
                })
            } else {
                this.params.budgetProName = '';
            }
        },
        async search() {
            this.params.currentPage = 1;
            await this.getStaffList();
        },
        async getStaffList() {
            let [err, res] = await awaitWrap( consumer_budget_getBudgetExecuteCollectList(this.params));
            if (err) {
                return
            }
            this.tableData = res.datas.list;
            this.total = res.datas.totalPage;
        },
        update() {},
        // 发生未结算金额
        showUsedSettlingAmountDetail(val, type) {
            console.log(val);
            this.reactInfoParams();
            this.showMoreSelect = false;
            this.infoParams.budgetUseType = type;
            // 预算使用类型 0.全部 ,1.占用 2.发生未结算，3.发生已结算
            this.infoParams.budgetProCode = val.budgetProCode;
            this.infoParams.budgetProName = val.budgetProName;
            this.infoParams.budgetProCodeAndName = val.budgetProCode + '.' + val.budgetProName;
            this.infoParams.budgetItemCode = val.budgetItemCode;
            this.infoParams.budgetItemName = val.budgetItemName;
            this.infoParams.budgetItemCodeAndName = val.budgetItemCode + '.' + val.budgetItemName;
            this.infoParams.budgetInsuranceCode = val.budgetInsuranceCode;
            this.infoParams.budgetInsuranceName = val.budgetInsuranceName;
            this.infoParams.budgetInsuranceCodeAndName = val.budgetInsuranceCode + '.' + val.budgetInsuranceName;
            this.infoParams.budgetOrgCode = val.budgetOrgCode;
            this.infoParams.budgetOrgName = val.budgetOrgName;
            let budgetDurationArr = val.budgetDuration.split('至');
            this.infoParams.budgetUsedStartDate = budgetDurationArr[0] + '-01';
            this.infoParams.budgetUsedEndDate = budgetDurationArr[1] + '-30';
            this.infoParams.budgetUsedDate = [budgetDurationArr[0] + '-01', budgetDurationArr[1] + '-30'];
            this.showUsedSettlingAmountDialog = true;
            this.onDetailSearch();
        },
        reactInfoParams() {
            this.infoParams = {
                "budgetInsuranceCode": "",
                "budgetInsuranceName": "",
                budgetInsuranceCodeAndName: '',
                "budgetItemCode": "",
                "budgetItemName": "",
                budgetItemCodeAndName: '',
                "budgetOrgCode": "",
                "budgetOrgName": "",
                "budgetProCode": "",
                "budgetProName": "",
                budgetProCodeAndName: '',
                "budgetUseType": 0,
                "budgetUsedEndDate": "",
                "budgetUsedStartDate": "",
                "createEndDate": "",
                "createStartDate": "",
                "currentPage": 1,
                "keyword": "",
                "orderBy": "",
                "pageSize": 10,
                "userName": "",
                "bizType": "",
                budgetUsedDate: [],
                createDate: [],
                total: 0,
            }
        },
        getBudgetItem() {
            consumer_budget_getBudgetItem().then(res=>{
                let budgetList = res.datas;
                budgetList.forEach(value=>{
                    value.value2 = value.code + '.' + value.value;
                })
                budgetList.unshift({value: '全部', code: '', value2: ''})
                this.budgetList = budgetList
            })
        },
        getBudgetInsurance() {
            consumer_budget_getBudgetInsurance().then(res=>{
                let budgetInsuranceList = res.datas;
                budgetInsuranceList.forEach(value=>{
                    value.value2 = value.code + '.' + value.value;
                })
                budgetInsuranceList.unshift({value: '全部', code: '', value2: ''})
                this.budgetInsuranceList = budgetInsuranceList;
            })
        },
        getBudgetPro() {
            consumer_budget_getBudgetPro().then(res=>{
                let budgetProLis = res.datas;
                budgetProLis.forEach(value=>{
                    value.value2 = value.code + '.' + value.value;
                })
                budgetProLis.unshift({value: '全部', code: '', value2: ''})
                this.budgetProLis = budgetProLis;
            })
        },
        getBudgetBizType() {
            consumer_budget_getBudgetBizType().then(res=>{
                let bizTypeLis = res.datas;
                bizTypeLis.forEach(value=>{
                    // value.value2 = value.code + '.' + value.value;
                })
                bizTypeLis.unshift({msg: '全部', type: ''})
                this.bizTypeLis = bizTypeLis;
            })
        },

        changeBudgetItem(val) {
            console.log(val);
            this.budgetList.forEach(value=>{
                if (value.value2 === val) {
                    this.infoParams.budgetItemCode = value.code;
                    this.infoParams.budgetItemName = value.value;
                }
            })
        },
        changeBudgetInsurance(val) {
            this.budgetInsuranceList.forEach(value=>{
                if (value.value2 === val) {
                    this.infoParams.budgetInsuranceCode = value.code;
                    this.infoParams.budgetInsuranceName = value.value;
                }
            })
        },
        changeBudgetPro(val) {
            this.budgetProLis.forEach(value=>{
                if (value.value2 === val) {
                    this.infoParams.budgetProCode = value.code;
                    this.infoParams.budgetProName = value.value;
                }
            })
        },
        changeBudgetUsedDate(val) {
            console.log(val)
            if (val) {
                this.infoParams.budgetUsedStartDate = val[0];
                this.infoParams.budgetUsedEndDate = val[1];
            } else {
                this.infoParams.budgetUsedStartDate = '';
                this.infoParams.budgetUsedEndDate = '';
            }
        },
        changeCreateDate(val) {
            if (val) {
                this.infoParams.createStartDate = val[0];
                this.infoParams.createEndDate = val[1];
            } else {
                this.infoParams.createStartDate = '';
                this.infoParams.createEndDate = '';
            }
        },
        more() {
            this.showMoreSelect = !this.showMoreSelect;
        },
        onDetailSearch() {
            this.infoParams.currentPage = 1;
            this.getDetailList();
        },
        async getDetailList() {
            this.detailLoading =true;
            let [err, res] = await awaitWrap( consumer_budget_getBudgetExecuteDetailList(this.infoParams));
            this.detailLoading = false;
            if (err) {
                return
            }
            this.detailList = res.datas.list;
            this.infoParams.total = res.datas.totalCount;
        },
        dialogName(val) {
            let text = '';
            if (!val) {
                return '';
            }
            switch (val) {
                case 2:
                    text = '发生未结算金额明细';
                    break
                case 3:
                    text = '发生已结算金额明细';
                    break
                case 1:
                    text = '占用金额明细';
                    break
            }
            return text;
        },
        tableName(val) {
            let text = '';
            if (!val) {
                return '';
            }
            switch (val) {
                case 2:
                    text = '发生未结算金额';
                    break
                case 3:
                    text = '发生已结算金额';
                    break
                case 1:
                    text = '占用金额';
                    break
            }
            return text;
        }
    }
}